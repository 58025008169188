import React from "react"

const BuyNowButton = ({ children, className, url, alt }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      alt={alt}
      className={`bg-yellow-200 md:text-xl lg:text-xl text-gray-700 font-bold uppercase
      rounded-full pt-4 pb-4 px-8 shadow-lg lg:tracking-wider leading-loose hover:bg-yellow-100
      hover:shadow-xl hover:text-gray-600 border-white border-2 ${className}`}
    >
      {children}
    </a>
  )
}

export default BuyNowButton
