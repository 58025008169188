import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import MainLayout from "../components/main-layout"
import SEO from "../components/seo"
import PlainMark from "../components/icons/plain-mark"
import Testimonial from "../components/testimonial"
import BuyNowButton from "../components/buy-button"

class Printables extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const rsh_ebook = data.rsh_ebook.img
    const katie_pic = data.katie.img
    const liz_pic = data.liz.img
    const leigh_ann_pic = data.leigh_ann.img
    const stacy_pic = data.stacy.img
    const devices_pic = data.devices.img
    const EBOOK_PRODUCT_LINK = "https://payhip.com/b/M7so"

    return (
      <MainLayout
        location={this.props.location}
        title={siteTitle}
        className="bg-gray-100"
      >
        <SEO
          title="Ready Set Homeschool - A Step By Step Guide to Start Homeschooling"
          image={rsh_ebook.fluid.src}
          description="So, you’ve decided to homeschool—that’s awesome! You might be a little excited, a lot nervous, and probably have no idea where to start..."
        />
        <PlainMark className="fixed w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
        <PlainMark className="fixed w-24 left-0 top-0 sm:ml-12 lg:ml-32 sm:mt-96 lg:mt-48 opacity-25 text-jnl-red-medium z-0 " />

        <div className="h-auto relative bg-indigo-100 overflow-hidden">
          <PlainMark className="absolute w-2/4 right-0 top-0 -mr-96 mt-48 opacity-25 text-jnl-teal-medium z-0 " />
          <PlainMark className="absolute w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
          <PlainMark className="absolute w-24 left-0 top-0 sm:ml-96 lg:ml-32 sm:mt-96 lg:mt-48 opacity-25 text-jnl-red-medium z-0 " />

          <div
            className="container pt-16 md:pb-48 mx-auto h-full flex flex-wrap sm:flex-col
                   md:flex-row lg:py-32 md:py-32 z-20"
          >
            <div className="lg:mt-0 w-full px-8 lg:px-2 lg:w-1/2 z-20">
              <h1>
                <div className="text-5xl md:text-5xl lg:text-4xl text-gray-800 mb-2 sm:mb-2 md:mb-4">
                  Are You Ready to
                </div>
                <div className="text-5xl md:text-6xl lg:text-6xl text-jnl-red-medium leading-6 lg:leading-8 mb-8">
                  Homeschool?
                </div>
              </h1>
              <p className="mt-4 mb-4 text-xl text-jnl-teal-dark font-bold">
                So, you’ve decided to homeschool—that’s awesome! You might be a
                little excited, a lot nervous, and probably have no idea where
                to start.
              </p>
              <p className="mt-4 mb-4 text-lg text-gray-800">
                It was not long ago that I was in the same shoes you are
                in—scouring the internet for the best homeschool books, reading
                books to figure out what to teach, and asking friends how THEY
                do it seemingly without effort, all the while hoping I’m not
                going to mess up my kids in the long run!
              </p>
              <p className="mt-4 mb-4 text-lg text-gray-800 font-bold">
                I just wanted SOMEBODY to tell me what to do!
              </p>
              <p className="mt-4 mb-4 text-lg text-gray-800">
                “Tell me what curriculum to use!”<br></br>
                “Tell me what books I should read!” <br></br>
                “Tell me how to structure my day!” <br></br>
                “For goodness sake, just hold my hand, please?!”<br></br>
              </p>
              <p className="mt-4 mb-4 text-lg text-gray-800">
                New Homeschool friend, I’m here. I’ll hold your hand.
              </p>
              <p className="mt-4 mb-4 text-xl text-gray-800 font-bold">
                Let's get you prepared to start homeschooling!
              </p>
            </div>
            <div className="w-full px-8 lg:px-2 lg:w-1/2 z-20 pb-16 lg:pb-0 text-center">
              <Image fluid={rsh_ebook.fluid} />
              <BuyNowButton url={EBOOK_PRODUCT_LINK}>
                Get the Guide
              </BuyNowButton>
            </div>
          </div>
        </div>

        <div className="h-auto relative bg-gray-900 overflow-hidden">
          <div
            className="container pt-16 md:pb-48 mx-auto h-full flex flex-wrap sm:flex-col items-center
                   md:flex-row lg:py-32 md:py-32 z-20"
          >
            <Testimonial
              name="Katie Bennett"
              photo={katie_pic}
              direction="horizontal"
              cites={[
                {
                  pre: "Creator of ",
                  text: "Embracing a Simpler Life",
                  url: "https://www.embracingasimplerlife.com/",
                },
                {
                  pre: "Author of ",
                  text: "Heavenly Minded Mom",
                  url: "https://amzn.to/2Y7u54H",
                },
              ]}
            >
              <p className="text-gray-200">
                <em>Ready Set Homeschool</em> provides a birds-eye overview of
                all you need to know to approach homeschooling for the first
                time. In this book, Ashley will put a “you’ve got this” hand on
                your shoulder, sit you down for a “it won’t be easy, but it will
                be worth it” heart to heart, and guide you through a basic
                framework for getting started as a brand new homeschooler.{" "}
                <em>Ready Set Homeschool</em> will clear the waters so you can
                see a way forward into the wonderful world of homeschooling.
              </p>
            </Testimonial>
          </div>
        </div>

        <div className="h-auto relative bg-indigo-100 overflow-hidden">
          <div className="container w-full px-8 pt-16 md:pb-24 mx-auto h-full flex flex-col md:flex-row lg:py-32 md:py-24 z-20">
            <div className="w-full md:w-2/4 lg:w-2/3 mt-6 lg:mt-0 lg:px-2 z-20">
              <h1>
                <div className="text-4xl md:text-5xl lg:text-5xl text-jnl-red-medium mb-2 sm:mb-2 md:mb-4">
                  My guide for new homeschoolers
                </div>
                <div className="text-xl md:text-3xl lg:text-2xl text-gray-800 font-sans">
                  will walk you through step by step when you aren't sure where
                  to start.
                </div>
              </h1>
              <div className="lg:py-4 text-jnl-teal-dark">
                <ul className="text-md lg:text-xl mt-8 mb-4 lg:pl-12 mx-auto list-disc lg:list-inside lg:leading-8 font-bold">
                  <li>Know your homeschooling "why"</li>
                  <li>Know how to pick curriculum</li>
                  <li>Discover your family's learning style</li>
                  <li>Get examples for affordable curriculum</li>
                  <li>Learn how to create a routine/schedule</li>
                  <li>Examples for how to structure your day</li>
                </ul>
              </div>
              <p className="mt-4 mb-4 text-lg text-gray-800">
                With <strong>SO many options out there</strong>, we’re going to
                take this <strong>one baby step at a time</strong> together.
              </p>
              <p className="mt-4 mb-4 text-lg text-gray-800">
                I will show you some of the options that have worked best for
                our family and <strong>help you decide what will work</strong>{" "}
                for yours.
              </p>
              <p className="mt-4 mb-12 text-lg text-gray-800">
                By the end of this guide you will be ready with{" "}
                <strong>a simple plan, a few books, and some confidence</strong>{" "}
                to get you on your way to educating your children at home.
              </p>
            </div>
            <div className="w-full md:w-2/4 mb-16 lg:px-8 text-center lg:mb-12">
              <Image fluid={devices_pic.fluid} />
              <BuyNowButton url={EBOOK_PRODUCT_LINK}>
                I'm Ready to Homeschool
              </BuyNowButton>
            </div>
          </div>
        </div>

        <div className="h-auto relative bg-jnl-teal-light overflow-hidden">
          <div
            className="container pt-16 md:pb-12 mx-auto h-full flex flex-col
                   md:flex-row lg:py-32 md:py-32 z-20"
          >
            <PlainMark className="absolute w-64 left-0 right-0 top-0 sm:-ml-24 -mt-24 opacity-25 text-jnl-yellow-dark z-0 " />
            <PlainMark className="absolute w-24 left-0 top-0 sm:ml-96 lg:ml-32 sm:mt-96 lg:-mt-96 opacity-25 text-jnl-red-medium z-0 " />
            <PlainMark className="absolute w-2/4 right-0 top-0 -mr-96 mt-48 opacity-25 text-jnl-teal-medium z-0 " />

            <div className="w-full sm:w-full lg:w-1/2 border-b mb-12 lg:pb-24 lg:mb-0">
              <Testimonial
                name="Leigh Ann Dutton"
                photo={leigh_ann_pic}
                direction="vertical"
                cites={[
                  {
                    pre: "Creator of ",
                    text: "Intentional By Grace",
                    url: "https://intentionalbygrace.com/",
                    className: "text-gray-800 underline",
                  },
                  {
                    pre: "Author of ",
                    text: "Fruit of the Spirit Curriculum for Toddlers",
                    className: "text-gray-800 underline",
                    url:
                      "https://store.intentionalbygrace.com/products/fruit-of-the-spirit-curriculum-for-toddlers",
                  },
                ]}
              >
                <p>
                  New homeschool families rest easy! Ashley has thought of
                  everything! <em>Ready Set Homeschool</em> will guide you
                  step-by-step through creating a homeschool plan that’s just
                  right for you and your kids without the fear of forgetting a
                  thing. I wish this resource was available when I started my
                  journey of home education! No doubt this guide will be a
                  blessing to the homeschool community!
                </p>
              </Testimonial>
            </div>
            <div className="w-full sm:w-full lg:w-1/2 border-b mb-12 lg:pb-24 lg:mb-0">
              <Testimonial
                name="Liz Millay"
                photo={liz_pic}
                direction="vertical"
                cites={[
                  {
                    pre: "Creator of ",
                    text: "Steadfast Family",
                    url: "https://www.steadfastfamily.com/",
                    className: "text-gray-800 underline",
                  },
                  {
                    pre: "Author of ",
                    text: "Play Through the Bible",
                    url: "https://amzn.to/2UgYeNY",
                    className: "text-gray-800 underline",
                  },
                ]}
              >
                <p>
                  When you first delve into the world of homeschooling, it is
                  easy to become overwhelmed! Ashley has put together a
                  comprehensive resource that is perfect for anyone new to
                  homeschooling. If you are planning or considering beginning
                  homeschooling, but you don’t know where to start, this eBook
                  will walk you through step by step!
                </p>
              </Testimonial>
            </div>
          </div>

          <div
            className="container md:pb-24 mx-auto h-full flex flex-wrap sm:flex-col items-center
                   md:flex-row z-20"
          >
            <Testimonial
              name="Stacy Myers"
              photo={stacy_pic}
              direction="horizontal"
              cites={[
                {
                  pre: "From ",
                  text: "Humorous Homemaking",
                  url: "https://www.instagram.com/humoroushomemaking/?hl=en",
                  className: "text-gray-800 underline",
                },
                {
                  pre: "Creator of ",
                  text: "Home Management How-To Course",
                  url: "https://courses.humoroushomemaking.com/",
                  className: "text-gray-800 underline",
                },
              ]}
            >
              <div className="space-y-4">
                <p>
                  I really flubbed up my first years of homeschooling -
                  thankfully I think my children won't need TOO much therapy,
                  but I wish I had this book when I started.
                </p>
                <p>
                  The problem was, I didn't know MYSELF and I didn't really take
                  into account my CHILDREN. Which is ridiculous because that's
                  the whole purpose of homeschool! To make it fit US. I tried to
                  fit a mold - and that's a recipe for disaster.
                </p>
                <p>
                  Being able to know not only my tendencies, but those of my
                  children, has made all the difference in our homeschool
                  journey.
                </p>
                <p>
                  This book is going to guide you in the right direction - not
                  only will you take an honest look at yourself, but you'll be
                  able to teach your kids so that they will thrive. I love
                  Ashley's descriptions of the different types of schooling so
                  you can choose what works best for your family. Not only that,
                  but after her explanations, she leads you to more sources to
                  continue learning!
                </p>
                <p>
                  Amazing resource for those who wish to homeschool - or those
                  who are homeschooling but feel like they're losing their mind.
                </p>
              </div>
            </Testimonial>
            <div className="mx-auto w-full mt-12 sm:w-1/2 lg:w-1/3 pb-16 lg:pb-0 text-center">
              <Image
                fluid={rsh_ebook.fluid}
                alt="Ready Set Homeschooling is a guide for new homeschoolers"
              />
              <BuyNowButton url={EBOOK_PRODUCT_LINK}>
                Get Started Here
              </BuyNowButton>
            </div>
          </div>
        </div>

        <div className="h-auto relative bg-indigo-100 overflow-hidden">
          <div
            className="container pt-16 pb-16 mx-auto h-full flex flex-col
                   md:flex-row lg:py-24 px-8 md:py-32 z-20"
          >
            <div className="lg:w-2/3 z-20">
              <h1>
                <div className="text-5xl md:text-5xl lg:text-5xl text-jnl-red-medium mb-2 sm:mb-2 md:mb-4">
                  Frequently Asked Questions
                </div>
              </h1>

              <div className="py-4 text-xl font-bold text-jnl-teal-dark">
                Do I have to read or research all the suggested homeschool
                resources and curriculums in the book?
              </div>
              <p className="mb-4 text-lg text-gray-800">
                Not at all! While this is meant to be a checklist to get you
                started, it also includes many great resources. Each of these
                resources are suggestions. This book could be used as a resource
                that you keep coming back to each year to find books to read to
                continue to help educate yourself as a home educator.
              </p>
              <div className="py-4 text-xl font-bold text-jnl-teal-dark">
                Does this tell me what curriculum to pick?
              </div>
              <p className="mb-4 text-lg text-gray-800">
                This is mainly used as a tool to help you to learn{" "}
                <strong>
                  <em>how</em>
                </strong>{" "}
                to pick curriculum. I don’t know each of you nor your children
                individually, so I can’t tell you which curriculums are
                guaranteed to work best. BUT I do provide great options for
                curriculums to use your first year homeschooling.
              </p>
              <div className="py-4 text-xl font-bold text-jnl-teal-dark">
                Is this a long book?
              </div>
              <p className="mb-12 text-lg text-gray-800">
                No! It’s intentionally written at a length that I believe is
                easy to read and quick to get started with, yet still provides
                enough depth to help you nail things for the long haul.
              </p>
              <BuyNowButton url={EBOOK_PRODUCT_LINK}>
                I'm Ready to Get Started
              </BuyNowButton>
            </div>
            <div className="w-full lg:px-8 lg:px-2 lg:w-1/3 z-20 text-center mt-8 lg:mb-12">
              <Image fluid={devices_pic.fluid} className="mb-8" />
            </div>
          </div>
        </div>
      </MainLayout>
    )
  }
}

export default Printables

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    rsh_ebook: file(relativePath: { eq: "ebooks/RSH-ebook-3D.png" }) {
      img: childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    katie: file(relativePath: { eq: "ebooks/testimonials/katie.jpg" }) {
      img: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    liz: file(relativePath: { eq: "ebooks/testimonials/liz.jpg" }) {
      img: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leigh_ann: file(relativePath: { eq: "ebooks/testimonials/leigh-ann.jpg" }) {
      img: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stacy: file(relativePath: { eq: "ebooks/testimonials/stacy.jpg" }) {
      img: childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    devices: file(
      relativePath: { eq: "ebooks/ready-set-homeschool-devices.png" }
    ) {
      img: childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
