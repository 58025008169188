import React from "react"
// import { Link } from "gatsby"

import Footer from "./footer"
import Nav from "./nav"

class MainLayout extends React.Component {
  render() {
    const { children, className } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    // let header

    return (
      <div className={`flex flex-col min-h-screen ${className}`}>
        <Nav />
        <main className="flex-grow overflow-hidden relative">{children}</main>
        <Footer />
      </div>
    )
  }
}

export default MainLayout
