import React from "react"
import Image from "gatsby-image"

const Testimonial = ({
  children,
  cites,
  photo,
  name,
  className,
  direction = "horizontal",
}) => {
  const get_photo_width = dir => {
    return dir === "horizontal" ? "lg:w-1/4" : ""
  }

  const get_quote_width = dir => {
    return dir === "horizontal" ? "lg:w-3/4" : ""
  }

  return (
    <>
      <div
        className={`w-full ${get_photo_width(
          direction
        )} ${className} pb-12 text-center`}
      >
        <Image
          fluid={photo.fluid}
          className="mx-auto h-64 w-64 rounded-full border-white border-4"
        />
        <div className="pt-4 text-lg font-bold uppercase text-gray-200">
          {name}
        </div>
        {cites &&
          cites.map(cite => (
            <div className="text-sm text-gray-300 font-italics">
              {cite.pre}
              <a
                href={cite.url}
                target="_blank"
                rel="noopener noreferrer"
                className={cite.className || `underline text-jnl-teal-light`}
              >
                {cite.text}
              </a>
            </div>
          ))}
      </div>
      <div className={`relative testimonial ${get_quote_width(direction)}`}>
        <blockquote className="text-xl lg:text-2xl px-12 mb-12 lg:mb-0 italic">
          {children}
        </blockquote>
      </div>
    </>
  )
}

export default Testimonial
